.tab__group{
    display: flex;flex-wrap: nowrap;justify-content: center;align-items: center;
    font-size: 2.0rem;
    @include sp {
        font-size: 1.6rem;
    }
}
.tab{
    flex-grow: 1;
    padding:10px;
    list-style:none;
    border-bottom:solid 4px $gray;
    text-align:center;
    cursor:pointer;
    -webkit-transition: all 0.3s ease;
    transition: all  0.3s ease;  
    &:hover {
        color: $red;
    }
}
.panel__group{
    font-size: 2.0rem;
}
.panel{
    display:none;
    >ul {
        >li {
            display: flex;flex-wrap: nowrap;justify-content: flex-start;align-items: center;
            font-size: 1.3rem;
            padding: 30px 0;
            border-top: 1px solid $gray;
            &:first-of-type {
                border-top: 0;
            }
            .sec__tab__panel__date {
                width: 140px;
                padding: 0 12px 0 0;
            }
            .sec__tab__panel__tag {
                width: 100px;
                color: $white;
                text-align: center;
                margin: 0 30px 0 0;
                padding: 5px;
                background: $red;
            }
            .sec__tab__panel__text {
                font-size: 1.6rem;
            }
            > .sec__tab__panel__text ,>a{
                width: calc(100% - 270px);
            }
            @include sp {
                flex-wrap: wrap;justify-content: space-between;align-items: center;
                padding: 10px 0 20px;
                .sec__tab__panel__date {
                    padding: 10px 0 0 0;
                }
                .sec__tab__panel__tag {
                    width: 100px;
                    margin: 0;
                    padding: 1px;
                }
                .sec__tab__panel__text {
                    font-size: 1.4rem;
                    padding: 10px 0 0 0;
                }
                >.sec__tab__panel__text ,>a{
                    width: 100%;
                }
            }
    }
    }
}
.tab.is-active{
    color: $red;
    border-bottom:solid 4px $red;
    transition: all 0.2s ease-out;
}
.panel.is-show{
    display:block;
}