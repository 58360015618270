#en {
    // top adjust
    line-height: 2.4rem;
    // .header__logo-l {
    //     width: 150px;
    // }
    .outline .sec__lead {
        line-height: 4rem;
        @include sp {
            line-height: 2.4rem;
        }
    }
    .sec__list>ul>li {
        align-self: stretch;
    }
    .sec__list>ul>li>a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer__nav02>ul>li {
        padding: 4px 0 0 0;
    }
    .spmenu__close:before {
        background: url(../images/en/spmenu_btn_close.png) center center/60% no-repeat;
    }
    @include sp {
        .spmenu__block:first-of-type>a:first-child {
            padding: 15px 10px 7px;
        }
        .spmenu__block:first-of-type>a:last-child {
            padding: 8px 10px;
        }
        .mv__text {
            top: 100px;
            font-size: 1.4rem;
        }
        .profile .sec__list>ul>li>a {
            font-size: 1.4rem;
        }
        .footer__nav02>ul>li>a {
            line-height: 1.8rem;
        }
        .footer__nav02>ul>li:nth-child(1), .footer__nav02>ul>li:nth-child(2), .footer__nav02>ul>li:nth-child(6), .footer__nav02>ul>li:nth-child(7) {
            width: 100%;
        }
    }

    // business
    .business .sec__text:first-of-type {
        line-height: 3.2rem;
        @include sp {
            line-height: 2.4rem;
        }
    }
    .business .sec__card__02__item .sec__card__title>span {
        line-height: 2rem;
        margin: 10px 0 0;
        @include sp {
            
        }
    }
    @include sp {
        .business .sec__card__text .sec__card__block {
            line-height: 2.4rem;
        }
        .sec__card__02__item .sec__card__subttl {
            line-height: 2.0rem;
        }
        .business .sec__column__list>p {
            line-height: 1.8rem;
            text-align: left;
        }
        .business .sec__column__list>p:before {
            bottom: 40%;
        }
        .business .sec__column__list>p:after {
            bottom: 36%;
        }
    }

    // product
    .product .sec__lead {
        line-height: 4rem;
        @include sp {
            line-height: 2.4rem;
        }
    }
    .product__disclaimer>ul>li:before {
        content: "*";
        top: 13px;
        @include sp {
            content: "*";
            top: 13px;
        }
    }

    // company
    .sec__title__01 {
        line-height: 4rem;
        @include sp {

        }
    }
    .header__nav__panel__wrapper>ul>li {
        align-self: stretch;
    }
    .job_information .sec__local__01 .sec__local__nav li ,
    .corporate_data .sec__local__01 .sec__local__nav li ,
    .corporate_philosophy .sec__local__01 .sec__local__nav li ,
    .company .sec__local__01 .sec__local__nav li {
        margin-left: 30px;
        line-height: 1.6rem;
        @include sp {

        }
    }
    .sec__local__01 {
        min-width: 720px;
        @include sp {
            min-width: auto;
        }
    }
    @include sp {
        .job_information .sec__local__01 .sec__local__nav li ,
        .job_information .sec__local__02 .sec__local__nav li ,
        .corporate_data .sec__local__01 .sec__local__nav li ,
        .corporate_data .sec__local__02 .sec__local__nav li ,
        .corporate_philosophy .sec__local__01 .sec__local__nav li ,
        .corporate_philosophy .sec__local__02 .sec__local__nav li ,
        .company .sec__local__01 .sec__local__nav li ,
        .company .sec__local__02 .sec__local__nav li {
            width: 100%;
            margin: 20px 0 0 0;
        }
    }
    .corporate_data .sec__row＿table td {
        word-break: break-all;
    }

    // contact
    .header__nav__panel__wrapper>ul>li>a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 20px 20px 20px 50px;
        @include sp {
            display: block;
            padding: 10px;
        }
    }
    .contact__notice>li:before {
        content: "*";
        top: 4px;
        @include sp {
            top: 2px;
        }
    }
    @include sp {
        .contact__table>dt {
            width: 35%;
        }
        .contact__table>dd {
            width: 65%;
        }
    }

    // topics
    .topics__disclaimer>ul>li:before {
        content: "*";
        top: 13px;
        @include sp {
        }
    }
}