.is-display {
    display: block !important;
    animation: show .3s ease;
}
.contactForm
    >.is-display {
    display: block !important;
    animation: show 0s ease;
}
@keyframes show{
    from{opacity: 0;}
    to{opacity: 1;}
}
.confirm {
    display: none;
    // opacity: 0;
    position: fixed;
    top: 0;left: 0;
    z-index: 100;
    width: 100vw;height: 100vh;
    background: rgba(0,0,0,.6);
    transition: all 0.3s ease;
    &__block {
        position: absolute;
        top: 0;left: 0;right: 0;bottom: 0;
        width: 500px;height: 250px;
        display: flex;justify-content: space-between;flex-wrap: wrap;align-items: center;
        background: $white;
        margin: auto;
        padding: 20px;
        z-index: 100;
        @include sp {
            width: 100%;
        }
    }
    &__close {
        position: absolute;
        top: -40px;right: 0;
        color: $white;
        padding: 0 30px;
        cursor: pointer;
        >span{
            display: block;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 12px;right: -30px;
                width: 30px;height: 1px;
                background: $white;
                transform: rotate(45deg);
            }
            &:after {
                content: "";
                position: absolute;
                top: 12px;right: -30px;
                width: 30px;height: 1px;
                background: $white;
                transform: rotate(-45deg);
            }
        }
        @include sp {
            padding: 0 40px;
            >span {
                &:before,&:after {
                    top: 9px;right: -30px;
                }
            }
        }
    }
    &__ttl {
        width: 100%;
        font-size: 1.6rem;
        text-align: center;
    }
    &__back{
        width: 46%;
        color: $white;
        text-align: center;
        background: $dark_gray;
        padding: 20px;
        cursor: pointer;
    }
    &__link{
        width: 46%;
        color: $white;
        text-align: center;
        background: $red;
        >a{
            display: block;
            color: $white;
            padding: 20px;
            cursor: pointer;
            >span {
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 10px;height: 8px;
                    bottom: 7px;right: -19px;
                    background: $white;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 4px;right: -15px;
                    width: 9px;height: 5px;
                    border-left: 1px $white solid;
                    border-bottom: 1px $white solid;
                }
            }
        }
    }
}

