.u-inline {
  display: inline;
}

.u-block {
  display: block;
}

.u-hide {
  display: none !important;
}

.u-hide-sp {
  @include sp {
    display: none !important;
  }
}

.u-hide-pc {
  @include tabpc {
    display: none !important;
  }
}
