.job_information {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .sec {
        &__head__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 70px 0 0 0;
            @include sp {
                display: block;
                margin: 30px 0 0 0;
            }
        }
        &__photo {
            margin: 40px 0 0 0;
        }
        &__local__01 {
            @include sp {
                margin: 30px 0 0 0;
            }
            .sec__local__nav {
                display: flex;
                justify-content: center;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &__local__02 {
            margin-top: 70px;
            @include sp {
                margin-top: 40px;
            }
            .sec__local__nav {
                display: flex;
                justify-content: flex-end;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &__row {
            margin-top: 70px;
            padding-bottom: 100px;
            @include sp {
                margin-top: 40px;
                padding-bottom: 50px;
            }
        }
    }
    .sec__row__data {
        margin: 10px 0 0 ;
    }
    .sec__card__02 {
        margin: 30px 0 0 0;
        flex-wrap: wrap;
        &__item {
            width: 48%;
        }
        @include sp {
            &__item {
                width: 100%;
                margin: 10px 0 0 0;
            }    
        }
    }
    .sec__card__02__item .sec__card__title {
        text-align: center;
    }
    .sec__card__title:before {
        left: 0;
        bottom: -6px;
    }
    .sec__column__list>p:before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 8px;
        bottom: 11px;
        left: 0;
        background: #e30b17;
    }
    .sec__card__text {
        display: flex;justify-content: space-between;flex-wrap: wrap;
        .sec__card__block {
            width: 100%;
            line-height: 2.8rem;
            margin: 20px 0 0;
            letter-spacing: .1rem;
            &:first-of-type {
                margin: 10px 0 0;
            }
            @include sp {
                width: 100%;
                margin: 20px 0 0;
            }    
        }
    }
    .sec__card__02__item {
        margin: 40px 0 0;
        padding: 40px;
        @include sp {
            margin: 20px 0 0;
            padding: 20px;
        }
    }
    .sec__column__list>p:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 9px;
        left: -2px;
        width: 9px;
        height: 5px;
        border-left: 1px #e30b17 solid;
        border-bottom: 1px #e30b17 solid;
    }
    .sec__column__list>p {
        position: relative;
        padding: 10px 0 0 20px;
        cursor: pointer;
    }
}
