.adjust__font01 {
    font-size: 2.1rem !important;
}
.mv {
    &__wrapper {
        position: relative;
        display: block;
        width: 100%;height: 580px;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;
            width: 100%;height: auto;
            background: url(../images/mv_photo_01.jpg) center center /100% auto no-repeat;    
            max-height: 600px;
        }
        @include sp {
            width: 100%;height: 240px;
            overflow: visible;
            &:before {
                background: url(../images/mv_photo_01.jpg) center center /100% auto no-repeat;    
            }
        }
    }
    &__inner {
        position: relative;
        max-width: 1240px;
        margin: 0 auto;
        height: inherit;
    }
    &__text {
        position: absolute;
        top: 230px;left: 0;
        color: $white;
        font-size: 2.4rem;
        font-weight: bold;
        background: $red;
        line-height: 4.2rem;
        padding: 20px 80px 20px 40px;
        // position: relative;
        z-index: 10;
        >p {
            position: relative;
            z-index: 10;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100%;
            background: $red;
            z-index: 5;
        }
        @include sp {
            top: 120px;left: 0;
            font-size: 1.6rem;
            line-height: 2.4rem;
            padding: 14px 20px 14px 14px;
            font-feature-settings: "palt" 1;
        }
    }
}

.notice {
    background: #f2f2f2;
    .panel {
        display: block !important;
    }
}

.outline {
    padding: 0 0 100px 0;
    @include sp {
        padding: 0 0 50px 0;
    }
    .sec__lead {
        margin: 70px 0 0;
        font-size: 2.1rem;
        @include sp {
            font-size: 1.4rem;
            margin: 40px 0 0;
        }
    }
    .sec__col {
        position: relative;
        justify-content: flex-end;
        margin: 70px 0 0;
        overflow: hidden;
        @include sp {
            margin: 40px 0 0;
        }
    }
    .sec__odd .sec__col__block:first-of-type{
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 5;
        width: 60%;
        @include sp {
            position: relative;
            width: 100%;
        }
    }
    .sec__even .sec__col__block:first-of-type{
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: 0;
        margin: auto;
        z-index: 5;
        width: 60%;
        @include sp {
            position: relative;
            width: 100%;
        }
    }
    .sec__odd .sec__col__block:nth-child(2),
    .sec__even .sec__col__block:nth-child(2){
        width: 60%;
        margin: 40px 0;
        padding: 40px;
        position: relative;
        z-index: 10;
        @include sp {
            width: 100%;
            margin: 20px 0 0;
            padding: 0;
        }
    }
    .sec__col__title {
        padding: 0 20px 10px 20px;
        @include sp {
            padding: 0 0 10px 0;
        }
    }
    .sec__col__title:before {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 1px;
        background: #bdbdbd;
    }
    .sec__col__text {
        margin: 20px 0 0 0;
        padding: 0 20px;
        @include sp {
            margin: 12px 0 0 0;
            padding: 0 0px;
        }
    }
    .btn__01 {
        margin: 50px auto 0;
        >a {
            padding: 10px 20px;
        }
        @include sp {
            margin:20px auto 0;
        }
    }
    .bg__gray {
        background: #f2f2f2;
        @include sp {
            background: none;
        }
    }
    .sec__odd .sec__col__block .sec__col__photo >img {
        right:0;
    }
    .sec__col__photo {
        position: static;
        min-width: 600px;
        min-height: 400px;
        >img {
            position: absolute;
            top:0;bottom: 0;
            margin: auto;
            width: auto;
            height: 100%;
        }
        @include sp {
            min-width: auto;
            min-height: auto;
                >img {
                    position: relative;
                    width: 100%;
                    height: auto;
            }    
        }
    }
    ul {
        text-align: left;
        >.notice {
            font-size: 1.4rem;
            font-weight: normal;
            letter-spacing: .1rem;
            // padding: 0 0 0 16px;
            &:before {
                content: "※";
                border: 0;
                top: 0 !important;
                left: -25px !important;
                background: none !important;
            }
            @include sp {
                font-size: 1.4rem;
            }
        }
    }
    .sec__col__list-cir>ul {
        padding: 0 0 0 30px;
        >li {
            &:before {
                top: 7px;
                left: -25px;
                @include sp {
                    top:5px;
                }
            }
        }
    }
}

.handled {
    background: $light_gray;
    padding: 100px 0 100px;
    @include sp {
        padding: 40px 0 50px;
    }
    .sec__title__lead {
        margin: 30px 0 0 0;
        @include sp {
            margin: 20px auto 0;
        }
    }
    @include sp {
        .center {
            text-align: center;
        }    
    }
    .sec__card__01 {
        margin: 70px 0 0;
        @include sp {
            margin: 30px 0 0;
            .sec__card__title {
                padding: 20px 10px;
            }        
        }
        >ul {
            flex-wrap: wrap;
            justify-content: center;
            >li {
                width: calc(94% /3);
                margin:10px;
                @include sp {
                    margin:1%;
                }
            }
        }
    }
    .sec__title__03 {
        margin: 70px auto 0;
        @include sp {
            margin: 30px auto 0;
        }
    }
    .btn__01 {
        margin: 40px auto 0;
        @include sp {
            margin:20px auto 0;
        }
    }
}

.news {
    background: #f2f2f2;
    padding: 100px 0;
    @include sp {
        padding: 40px 0 50px;
    }
    .sec__title__lead {
        margin: 30px 0 0 0;
        @include sp {
            margin: 10px 0 0 0;           
        }
    }
    @include sp {
        .center {
            text-align: center;
        }    
    }
    .btn__01 {
        margin: 100px auto 0;
        @include sp {
            margin: 20px auto 0;
        }
    }
    .tab__panel {
        margin: 70px 0 0;
        @include sp {
            margin: 20px 0 0;
        }
    }
    .panel>ul>li {
        border-top: 1px solid #bdbdbd;
    }
}

.profile {
    position: relative;
    color: $white;
    &:before {
        content: "";
        position: absolute;
        top: 0;left: 0;right: 0;bottom: 0;
        width: 100%;height: auto;
        background: url(../images/profile_01.png) center center /cover no-repeat;    
        z-index: 10;
    }
    &__wrapper {
        position: relative;
        display: block;
        width: 100%;
        padding: 100px 0;
        overflow: hidden;
        z-index: 20;
        @include sp {
            padding: 40px 10px 50px;
        }
    }
    .sec__title__lead {
        margin: 30px 0 0 0;
        @include sp {
            margin: 10px 0 0 0;
        }
    }
    @include sp {
        .center {
            text-align: center;
        }    
    }
    .sec__wrapper {
        margin: 70px auto 0;
        @include sp {
            margin: 20px 0 0 0;
        }
    }
    @include sp {
        .sec__list>ul>li>a {
            font-size: 1.6rem;
        }
    }
}