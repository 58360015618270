@charset "utf-8";

// Style propatyの順番
// .expample {
//   position: ***;
//   display: ***;
//   width: ***;
//   margin: ***;
//   font: ***;
//   background: ***;

/* ========================================================= */
/* BASE SCSS                                                 */
/* ========================================================= */
@import "base/_values";
@import "base/_mixin";
@import "base/_ress";
@import "base/_base";
@import "base/_layout";


/* ========================================================= */
/* MODULE SCSS                                               */
/* ========================================================= */
@import "module/_btn";
@import "module/_tab";
@import "module/_confirmation";
@import "module/_forjs";
@import "module/_loading";


/* ========================================================= */
/* PAGES SCSS                                               */
/* ========================================================= */
@import "pages/_index";
@import "pages/_business";
@import "pages/_product";
@import "pages/_corporate_philosophy";
@import "pages/_company";
@import "pages/_corporate_data";
@import "pages/_job_information";
@import "pages/_contact";
@import "pages/topics";

/* ========================================================= */
/* UTILITY SCSS                                               */
/* ========================================================= */
@import "utility/_display";


// #form {
//     >input {
//         margin: 10px auto;
//         border: 1px solid;
//         padding: 10px;
//         display: block;
//     }
// }
.mailsent {
    display: block;
    width: 500px;
    text-align: center;
    margin: 10px auto;
    padding: 10px;
    cursor: pointer;
    border: 1px solid $red;
    transition: all .3s ease;
    &:hover {
        color: $white;
        background: $red;
    }
}

/* ========================================================= */
/*  EN ADJUST SCSS                                               */
/* ========================================================= */
@import "base/_en_adjust";

