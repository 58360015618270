.topics {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .sec__title__01 {
        margin: 70px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }

    .sec__row {
        margin-top: 70px;
        @include sp {
            margin: 30px 0 0 0;
        }
    }

    .sec__card__02 {
        flex-wrap: wrap;
        margin-top: 70px;
        @include sp {
            margin: 30px 0 0 0;
        }
    }

    .sec__card__02__item {
        &:nth-of-type(n+3) {
            margin: 30px 0 0 0;
        }
        @include sp {
            &:nth-of-type(n+2) {
                margin: 30px 0 0 0;
            }
        }
    }
    .sec__card__date {
        display: inline-block;
        width: 120px;
        padding: 0 12px 0 0;
        font-size: 1.3rem;
        @include sp {
            padding: 10px 0 0 0;
        }
    }

    .sec__card__tag {
        display: inline-block;
        width: 100px;
        color: $white;
        text-align: center;
        margin: 0 40px 0 0;
        padding: 5px;
        background: $red;
        font-size: 1.3rem;
        @include sp {
            width: 100px;
            margin: 0;
            padding: 1px;
        }
    }

    .sec__card__title {
        margin-top: 20px;
    }

    .sec_card__photo {
        margin-top: 30px;
    }

    .tab {
        text-align: left;
        cursor: auto;
        &:hover {
            color: unset;
        }
        &__panel {
            margin: 70px 0 0 ;
            @include sp {
                margin: 30px 0 0 ;
            }
        }
    }
    &__disclaimer {
        >p {
            font-size: 1.6rem;
            font-weight: bold;
        }
        >ul {
            padding: 0 0 0 20px;
            font-size: 1.4rem;
            @include sp {
                padding: 10px 0 0 20px;
            }
            >li {
                position: relative;
                padding: 10px 0;
                &:before {
                    content: "※";
                    display: inline-block;
                    position: absolute;
                    top: 11px;
                    left: -19px;
                    @include sp {
                        top: 10px;
                    }
                }
                >p {
                    display: block;
                    padding: 10px 0 10px 12px;
                    &:before {
                        content: ">";
                        position: relative;
                        top:-2px;left: -10px;
                    }
                    >a {
                        color: #023796;
                        text-decoration: underline;
                        transition: all .3s ease;
                        &:hover {
                            color: #e30b17;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}