/* ========================================================= */
/* LOADING                                               */
/* ========================================================= */

#loading {
    pointer-events: none;
    display: block;
    width:100vw;
    height: 100vh;
    background: rgba(0,0,0,.6);
    position: fixed;
    top: 0;
    z-index: 999;
    &:before {
        content: "";
        position: absolute;
        top: 0;right: 0;left: 0;bottom: 0;
        width: 100px;height: 100px;
        border-radius: 50px;
        margin: auto;
        background: url(../images/loading.svg) center center /50% no-repeat rgba(0,0,0,.6);
    }
}

.eventnone {
    pointer-events: none;
}