.company {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .sec {
        &__head__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 70px 0 0 0;
            @include sp {
                display: block;
                margin: 30px 0 0 0;
            }
        }

        &__local__01 {
            @include sp {
                margin: 30px 0 0 0;
            }
            .sec__local__nav {
                display: flex;
                justify-content: center;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &__row {
            display: flex;
            justify-content: space-between;
            margin-top: 70px;
            @include sp {
                margin-top: 40px;
                flex-flow: column-reverse;
            }
        }

        &__column__01 {
            width: 50%;
            margin-top: -7px;
            @include sp {
                margin-top: 30px;
                width: inherit;
            }
        }

        &__column__02 {
            width: 46%;
            @include sp {
                width: inherit;
                margin-top: 0;
            }
        }

        &__column__title {
            font-size: 2.8rem;
            font-weight: bold;
            @include sp {
                font-size: 1.8rem;
            }
        }

        &__column__text {
            margin-top: 40px;
            &:first-child {
                margin-top: 0;
            }
            @include sp {
                margin-top: 20px;
            }
        }

        &__column__name {
            margin-top: 85px;
            @include sp {
                width: inherit;
                margin-top: 15px;
            }
        }

        &__column__position {
            font-size: 1.4rem;
            text-align: right;
            line-height: 2rem;
        }

        &__column__author {
            margin-top: 20px;
            font-size: 2rem;
            font-weight: bold;
            text-align: right;
            @include sp {
                margin-top: 10px;
                font-size: 1.6rem;
            }
        }

        &__local__02 {
            margin-top: 70px;
            @include sp {
                margin-top: 40px;
            }
            .sec__local__nav {
                display: flex;
                justify-content: flex-end;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    
    }
}