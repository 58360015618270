/* ========================================================= */
/* BASE                                               */
/* ========================================================= */
html {
  font-family: $font-family;
  font-size: $font-size;
  letter-spacing: .1rem;
}

body {
  font-size: 1.6rem;
  color: $default;
  @include sp {
    font-size: 1.4rem;
  }
}

:focus {
  outline: 0;
}

button,
input,
textarea,
optgroup {
  &:focus {
    box-shadow: 0 0 0 2px color(focus);
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  color: $default;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all  0.3s ease;
  &:hover {
    color: $default_link_color_hover;
  }
}

header {

}

.main {
overflow: hidden;
  @include sp {
    padding: 50px 0 0 0;
  }
}

footer {
  
}