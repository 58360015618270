// 追従用
.is-fixed-nav {
    top: 0 !important;
}
.is-menu-view {
    left: 0 !important;
}
.is-icon-rotate {
    &:before {
        transform: rotate(90deg) !important;
    }
    &:after {
        transform: rotate(270deg) !important;
    }
}
.is-gnav-active {
    >a {
        color: $red !important;
    }
}

header {
    @include sp {
        position: fixed;
        width: 100%;
        background: $white;
        z-index: 100;
    }
}

.header {
    &__wrapper {
        // position: relative;
        display: flex; justify-content: space-between;
        width: 100%; max-width: 1240px; height: 90px;
        margin: 0 auto; padding: 0 $pc_pd;
        @include sp {
            display: flex; justify-content: flex-start;
            width: 100%; max-width: auto; height: 50px;
            margin: 0 auto; padding: 0;    
            border-bottom: 4px solid $red;
        }
    }
    &__logo {
        &-l {
            display: flex; align-items: center;
            >img {
                max-width: 315px;
            }
            @include sp {
                width:30%;
                max-width: 145px;
                padding: 10px 10px 10px 10px;
                >img {
                    display: block;
                    width:100%;max-width: auto;
                }
            }
        }
        &-r {
            display: flex; align-items: center;justify-content: flex-end;
            width:50%;
            >img {
                max-width: 375px;
            }
            @include sp {
                margin: 0 0 0 auto;
                padding: 10px 10px 10px 0;
                >img {
                    width:100%;max-width: auto;
                }
            }
        }
    }
    &__spmenu {
        @include sp {
            position: relative;
            display: block;
            width:16%;
            max-width:62px;
            border-right: 1px solid $gray;
            &:before {
                content: "";
                position: absolute;
                top:0;right: 0;left: 0;bottom: 0;
                width:100%;height: 100%;
                margin: auto;
                background: url(../images/spmenu_btn.png) center center /55% no-repeat;
            }
        }    
    }
    &__nav {
        position: relative;
        border-top: 4px solid $red;

        &__conpact {
            position: fixed;
            width: 100%;
            background: $white;
            display: flex; flex-wrap: nowrap;justify-content: space-between; align-items: center;
            height: 90px;
            border-bottom: 4px solid $red;
            z-index: 30;
            top:-90px;
            -webkit-transition: all 0.3s ease;
            transition: all  0.6s ease;

            .header__wrapper {
                width:150px;
            }
            .header__nav {
                width: calc(100% - 150px);
                border-top: 0;
            }
            .header__nav__wrapper {
                >ul,>ul:first-child,>ul:last-child {
                    width: 100%;
                    font-size: 1.6rem;
                    >li {
                        padding: 0;
                    }
                }
            }
            .header__nav__panel {
                top: 89px; left: 0;right: 0;
            }
        }
        &__wrapper {
            display: flex; justify-content: space-between;
            width: 100%; max-width: 1240px;
            margin: 0 auto; padding: 0 0 0 20px;
            >ul {
                display: flex; flex-wrap: nowrap; align-items: center;
            }
            >ul:first-child {
                justify-content: flex-start;
                width:85%;
                >li {
                    width: calc( 100% / 6);
                    >a {
                        position: relative;
                        display: block;
                        font-weight: bold;
                        text-align: center;
                        padding: 30px 10px;
                        &:before {
                            content: "";
                            position: absolute;
                            top:auto; right: -1px; bottom: auto;
                            width: 1px; height: 24px;
                            background: $gray;
                        }
                        &:hover {
                            color: $red;
                            background: $light_gray;
                            &:before {
                                background: $white;
                            }
                        }
                    }
                }
                >li:first-child {
                    >a{
                        &:after {
                            content: "";
                            position: absolute;
                            top:auto; left: 0px; bottom: auto;
                            width: 1px; height: 24px;
                            background: $gray;
                        }
                        &:hover {
                            &:after {
                                background: $white;
                            }
                        }
                    }
                }
            }
            >ul:last-child {
                justify-content: flex-end;
                width:15%;
                font-size: 1.2rem;
                >li {
                    padding: 0 10px;
                }
                .active {
                    >a {
                        color: $red;
                        font-weight: bold;
                    }
                }
            }    
        }
        &__panel {
            display: none;
            position: absolute;
            // display: block;
            top: 80px; left: 0;right: 0;
            width: 100%; max-width: 1240px;
            background: $light_gray;
            margin: auto;
            padding: 10px;
            z-index: 20;
            &:before {
                content: "";
                display: block;
            }
            &__wrapper {
                position: relative;
                font-weight: bold;
                >ul {
                    position: relative;
                    display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;
                    width: 100%;
                    padding: 30px 30px;
                    background: $white;
                    >li {
                        width: calc(94% / 3 );
                        text-align: center;
                        border: 1px solid $gray;
                        margin: 0 10px;
                        >a {
                            position: relative;
                            display: block;
                            padding: 20px;
                            &:before {
                                content: '';
                                position: absolute;
                                top: 50%;left: 20px;
                                width: 8px;height: 8px;
                                border: 0px;
                                border-top: solid 2px $dark_gray;
                                border-right: solid 2px $dark_gray;
                                -ms-transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                                margin: -4px 0 0 0;
                            }
                            &:hover {
                                // background: $light_gray;
                                &:before {
                                    border-top: solid 2px $red;
                                    border-right: solid 2px $red;    
                                }
                            }
                        }
                        
                    }
                }
                .btn__close {
                    position: absolute;
                    bottom: -30px;left: 0;right: 0;
                    width: 260px;
                    text-align: center;
                    margin: auto;
                    padding: 15px;
                    >p {
                        position: absolute;
                        top: 12px;right: 0;left: 0;
                        z-index: 10;
                        cursor: pointer;
                        >span {
                            display: block;
                            position: absolute;
                            top: 2px;right: 30%;
                            width: 20px;height: 20px;
                            &:before,&:after {
                                display: block;
                                content: "";
                                position: absolute;
                                top: 50%;left: 50%;
                                width: 84%;height: 10%;
                                margin: -4% 0 0 -42%;
                                background: $dark_gray;
                            }
                            &:before {
                                transform: rotate(-45deg);
                            }
                            &:after {
                                transform: rotate(45deg);
                            }
                        }
                    }
                    // background: #000;
                    &:before{
                        content: "";
                        position: absolute;
                        bottom: -20px;left: 0;right: 0;                            
                        border-top: 40px solid $light_gray;
                        border-left: 30px solid transparent;
                        border-right: 30px solid transparent;    
                        z-index: 2;
                    }
                }    
            }
        }
    }
}

.footer {
    &__nav__wrapper {
        border-top: 1px solid $gray;
        border-bottom: 4px solid $red;
    }
    &__nav01 {
        >ul {
            display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;
            width: 85%; max-width: 1240px;
            margin: 0 auto; padding: 0 $pc_pd;
            >li {
                width: calc( 100% / 6);
                >a {
                    position: relative;
                    display: block;
                    font-weight: bold;text-align: center;
                    padding: 20px 10px;
                    &:before {
                        content: "";
                        position: absolute;
                        top:auto; right: -1px; bottom: auto;
                        width: 1px; height: 24px;
                        background: $gray;
                    }
                    &:hover {
                        color: $red;
                        // background: $gray;
                        &:before {
                            right: 0;
                        }
                    }
                }
            }
            >li:first-child {
                >a{
                    &:after {
                        content: "";
                        position: absolute;
                        top:auto; left: 0px; bottom: auto;
                        width: 1px; height: 24px;
                        background: $gray;
                    }
                }
            }
            @include sp {
                display: block;
                width: 100%; max-width: none;
                margin: 0 auto; padding: 0 $sp_pd;
                >li {
                    width: 100%;
                    >a {
                        font-size: 1.6rem;
                        padding: 20px 10px;
                        &:before {
                            top:auto; right: 0; bottom: 0;
                            width: 100%; height: 1px;
                        }
                    }
                }
                >li:first-child {
                    >a{
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
    &__nav02 {
        width: 100%; max-width: 1240px;
        margin: 0 auto; padding: 0 $pc_pd;
        >ul {
            display: flex; flex-wrap: wrap; justify-content: center; align-items: center;
            font-size: 1.3rem;
            margin: 20px auto 0;
            >li {
                padding: 20px 0 0 0;
                >a {
                    position: relative;
                    display: inline-block;
                    padding: 0 20px 0 14px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;left: 0;
                        width: 6px;height: 6px;
                        border: 0px;
                        border-top: solid 1px $default;
                        border-right: solid 1px $default;
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        margin: -4px 0 0 0;
                    }
                    &:hover {
                        &:before {
                            border-top: solid 1px $red;
                            border-right: solid 1px $red;
                        }    
                    }
                }
            }
            @include sp {
                font-size: 1.2rem;
                margin: 10px auto 0;
                >li {
                    padding: 20px 0 0 0;
                    width: 100%;
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(6),
                    &:nth-child(7)
                    {
                        width: 50%;
                    }
                }    
            }
        }
    }
    &__copy {
        font-size: 1.2rem;
        text-align: center;
        margin: 50px auto 35px;
        @include sp {
            font-size: 1rem;
            text-align: left;
            margin: 30px auto;
            padding: 0 0 0 10px;
        }
    }
}

.bread {
    background: $light_gray;
    >div {
        >ul {
            display: flex;flex-wrap: nowrap;justify-content:flex-start;align-items: center;
            >li {
                font-size: 12px;
                padding: 15px 30px 15px 0;
                &:last-child {
                    font-weight: bold;
                }
                >a {
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 55%;right: -16px;
                        width: 6px;height: 6px;
                        border: 0;border-top: solid 1px #333;border-right: solid 1px #333;
                        transform: rotate(45deg);
                        margin: -4px 0 0 0;
                    }
                }
            }
        }
    }
}

.sec {
    &__error {
        width: 80%;
        text-align: center;
        font-size:1.6rem;
        font-weight:bold;
        margin:70px auto 0;
        padding:$pc_pd;
        border: 4px solid $gray;
        &-phone {
            font-size: 3.0rem;
            margin: 30px 0 0;
        }
    }
    &__wrapper {
        width: 100%; max-width: 1240px;
        margin: 0 auto; padding: 0 $pc_pd;
        @include sp {
        width: 100%; max-width: none;
        margin: 0 auto; padding: 0 $sp_pd;
        }
    }
    &__title {
        &__01 {
            position: relative;
            font-size: 3.5rem;
            font-weight: bold;
            padding: 14px 0 14px 40px;
            font-feature-settings: "palt" 1;
            &:before {
                content: "";
                position: absolute;
                top:0;left: 0;
                width: 5px;height: 100%;
                background: $red;
            }
            @include sp {
                font-size: 2.4rem;
                padding: 14px 0 14px 40px;
                &:before {
                    top:0;left: 0;
                    width: 5px;height: 100%;
                }
            }
        }
        &__02 {
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            letter-spacing: .5rem;
            font-feature-settings: "palt" 1;
            @include sp {
                font-size: 2.4rem;
            }
        }
        &__03 {
            position: relative;
            display: table;
            font-size: 2.6rem;
            font-weight: bold;
            text-align: center;
            margin: 0 auto;
            font-feature-settings: "palt" 1;
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;height: 2px;
                background: $red;
            }
            @include sp {
                font-size: 1.8rem;
                text-decoration: underline solid $red;
                text-underline-position: under;
                text-decoration-thickness: 2px;
                &:before {
                    content: none;
                }
            }
        }
        &__04 {
            position: relative;
            font-size: 2.4rem;
            font-weight: bold;
            padding: 0 0 0 20px;
            font-feature-settings: "palt" 1;
            &:before {
                content: "";
                position: absolute;
                top:0;left: 0;
                width: 5px;height: 100%;
                background: $red;
            }
            @include sp {
                font-size: 1.6rem;
            }
        }
        &__lead {
            text-align: center;
            @include sp {
                text-align: left;
                text-align: justify;
                text-justify: inter-ideograph;
            }
        }
    }
    &__lead {
        font-size: 2.4rem;
        font-weight: bold;
        @include sp {
            font-size: 1.4rem;
        }
    }
    &__center {
        text-align: center;
    }
    &__list {
        >ul {
            display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: center;
            >li {
                width: calc(96% /4);
                border: 1px solid $white;
                >a {
                    color: $white;
                    display: block;
                    font-size: 2.0rem;
                    font-weight: bold;
                    text-align: center;
                    padding: 20px;
                    &:hover {
                        color: $red;
                        background: rgba(255,255,255,1);
                    }
                }
            }
        }
        @include sp {
            >ul {
                flex-wrap: wrap;
                >li {
                    width: calc(97% /2);
                    margin: 10px 0 0 0;
                    >a {
                        font-size: 1.4rem;
                        padding: 16px;
                    }
                }
            }
        }
    }
    &__col {
        display: flex;flex-wrap: nowrap;justify-content: center;align-items: center;
        @include sp {
            display: block;
        }
        &__block {
            width: 50%;
            @include sp {
                width: 100%;
            }
        }
        &__photo {
            position: relative;
            z-index: 20;
        }
        &__title {
            position: relative;
            font-size: 2.6rem;
            font-weight: bold;
            z-index: 10;
            font-feature-settings: "palt" 1;
            &:before {
                content: "";
                position: absolute;
                bottom: -10px;
                width: 500%;height: 2px;
                background: $red;
            }
            @include sp {
                font-size: 1.8rem;
                &:before {
                    width: 100%;height: 2px;
                    bottom: -6px;
                }
            }
        }
        &__list {
            &-arrow {
                >ul {
                    margin: 44px 0 0 0;
                    >li {
                        position: relative;
                        margin: 24px 0 0 0;
                        &:before {
                            content:  "";
                            display:  inline-block;
                            position:  absolute;
                            top: 7px;left: -14px;
                            width: 0;height: 0;
                            border-style: solid;
                            border-width: 4px 0 4px 6px;
                            border-color: transparent transparent transparent $red;
                            vertical-align:  middle;
                        }
                    }
                }    
                @include sp {
                    >ul {
                        padding: 0 0 0 14px;
                        >li {
                            margin: 14px 0 0 0;
                        }
                    }
                }
            }
            &-cir {
                >ul {
                    font-size: 1.6rem;
                    font-weight: normal;
                    margin: 44px 0 0 0;
                    padding: 0 0 0 20px;
                    >li {
                        position: relative;
                        margin: 24px 0 0 0;
                        &:before {
                            content:  "";
                            display:  inline-block;
                            position:  absolute;
                            top: 7px;left: -19px;
                            width: 10px;height: 10px;
                            background: $red;
                            border-radius: 10px;
                        }
                    }
                }    
                @include sp {
                    >ul {
                        font-size:1.4rem;
                        padding: 0 0 0 14px;
                        >li {
                            margin: 14px 0 0 0;
                        }
                    }
                }
            }
        }
    }
    &__odd {
        flex-direction: row-reverse;
        .sec__col__block:nth-child(2) {
            padding: 40px 0 40px 40px;
        }
        .sec__col__title {
            text-align: left;
            &:before {
                right: 0;
            }
        }
        .sec__col__list-arrow >ul >li:before {
            top: 7px;left: -14px;
        }
        @include sp {
            .sec__col__block:nth-child(2) {
                padding: 10px 0 0 0;
            }
            .sec__col__title {
                text-align: left;
            }
        }
    }
    &__even {
        flex-direction: row;        
        .sec__col__block:nth-child(2) {
            padding: 40px 40px 40px 0;
        }
        .sec__col__title {
            text-align: left;
            &:before {
                left: 0;
            }
        }
        .sec__col__list-arrow >ul >li {
            padding: 0 0 0 14px;
        }
        .sec__col__list-arrow >ul >li:before {
            top: 7px;left: 0;
        }
        @include sp {
            .sec__col__block:nth-child(2) {
                padding: 10px 0 0 0;
            }
        }
    }
    &__card {
        &__01 {
            .sec__card__title {
                font-size: 1.8rem;
                font-weight: bold;
                text-align: center;
                padding: 20px;
                background: $white;
            }
            >ul {
                display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: stretch;
                >li {
                    width: calc(94% /4);
                    background: $white;
                }
            }
            @include sp {
                .sec__card__title {
                    font-size: 1.4rem;
                    padding: 10px 10px;
                    font-feature-settings: "palt" 1;
                }
                >ul {
                    flex-wrap: wrap;align-items: stretch;
                    >li {
                        width: calc(96% /2);
                        margin: 10px 0 0 0;
                    }
                    >li:nth-child(odd) {
                        .sec__card__title {
                            padding: 20px 10px;
                        }
                    }
                }    
            }
        }
        &__02 {
            display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: stretch;
            &__item{
                width: calc(96% /2);
                background: $white;
                border: 1px solid $gray;
                padding: 24px;
                .sec__card__title {
                    position: relative;
                    display: block;
                    font-size: 2.6rem;
                    font-weight:bold;
                    text-align: left;
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 2px;
                        background: #e30b17;
                    }
                }
                .sec__card__subttl {
                    display: table;
                    position: relative;
                    font-weight: bold;
                    text-align: center;
                    margin: 20px 0 10px;
                    padding: 0 20px;
                    &:first-of-type {
                        margin: 0 0 10px;
                    }
                    &:before,&:after {
                        content: "";
                        position: absolute;
                        width: 10px;height: 1px;
                        background: $default;
                    }
                    &:before {
                        top: 48%;left: 0;
                    }
                    &:after {
                        top: 48%;right: 0;
                    }
                }
                .sec__card__text {
                    margin: 24px 0 0 0;
                }
            }
            @include sp {
                flex-wrap: wrap;
                &__item{
                    width: calc(100% /1);
                    padding: 12px;
                    .sec__card__title {
                        font-size: 1.6rem;
                    }
                    .sec__card__text {
                        margin: 10px 0 0 0;
                    }
                }    
            }
        }
    }

}

.spmenu {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;justify-content: center;align-items: flex-start;
    position: absolute;
    top: 0;left: -100%;
    width: 100%;height: 100vh;
    font-size: 1.6rem;
    // background: rgba(0,0,0,.3);
    z-index:80;
    &__bg {
        position: absolute;
        right:0;
        width: 100vw;height: 200vh;
        background: rgba(0,0,0,.3);
        z-index: 80;
    }
    &__panel {
        width: calc(100% - 56px);
        background: $white;
        z-index:100;
    }
    &__block {
        display: flex;justify-content: center;align-items: center;flex-wrap: wrap;
        width:100%;
        &:first-of-type {
            border-bottom: 4px solid $red;
            height: 56px;
            >a:first-child {
                padding: 14px 10px 9px;
            }
            >a:last-child {
                padding: 10px;
            }
        }
        &:last-of-type {
            border-top: 4px solid $red;
        }
    }
    &__list {
        &__01 {
            display: flex;flex-wrap:wrap;justify-content: center;align-items:center;
            width:100%;
            padding: 0 0;
            >li {
                width:100%;
                font-weight: bold;
                text-align: left;
                border-bottom: 1px solid $gray;
                margin: 0 10px;
                padding: 16px 0;
                >a,>p {
                    padding: 0 10px;
                }
            }        
        }
        &__02 {
            display: flex;flex-wrap:wrap;justify-content: space-between;align-items:center;
            width:100%;
            margin: 10px 0;
            padding: 5px 10px;
            >li {
                width:48%;
                color: $light_gray;
                text-align: center;
                border: 1px solid $gray;
                padding: 10px 0;
                height: 46px;
                line-height: 2rem;
            }        
            .active {
                font-weight: bold;
                border: 1px solid $red;
                >a {
                    color: $red;
                    font-weight: bold;
                }
            }
        }
    }
    &__logo {
        padding: 10px;
        @include sp {
            &:first-of-type {
                width: 35%;
                >img {
                    width: 83px;
                }
            }
            &:last-of-type {
                width: 65%;
            }    
        }
    }
    &__close {
        position: relative;
        width: 56px;height: 56px;
        display: block;
        background: $red;
        z-index:100;
        &:before {
            content: "";
            position:absolute;
            top:0;left:0;right:0;bottom:0;
            width:100%;height:100%;
            margin:auto;
            background: url(../images/spmenu_btn_close.png) center center /60% no-repeat
        }
    }
    &__panel__close {
        position: relative;
        padding:14px ;
        font-weight: bold;
        >span {
            display: block;
            position: absolute;
            top: 32%;right: -20%;
            width: 20px;height: 20px;
            &:before,&:after {
                display: block;
                content: "";
                position: absolute;
                top: 50%;left: 50%;
                width: 84%;height: 10%;
                margin: -8% 0 0 -42%;
                background: $dark_gray;
            }
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
        }
    }
    &__second {
        position: relative;
        &:before,&:after {
            content: "";
            position: absolute;
            top: 30px;
            right: 20px;
            width: 2px;
            height: 16px;
            background: $red;
            margin: -10px 0 0;
            -webkit-transition: all 0.3s ease;
            transition: all  0.3s ease;
        }
        &:before {
            transform: rotate(0deg);
        }
        &:after {
            transform: rotate(90deg);
        }
        .header__nav__panel {
            position: relative;
            top: 0;
            background: #f7f7f7;
            margin: auto;
            padding: 10px;
            z-index: 20;
        }
        .header__nav__panel__wrapper>ul {
            flex-wrap: wrap;
            background: none;
            padding: 10px;
        }
        .header__nav__panel__wrapper>ul>li {
            width: 100%;
            font-weight: normal;
            text-align: left;
            margin: 0;
            border:0;
        }
        .header__nav__panel__wrapper>ul>li>a {
            padding:10px 0;
        }
        .header__nav__panel {
            margin: 15px 0 -16px 0;
        }
        .header__nav__panel__wrapper>ul>li>a:before {
            content: none;
        }
    }
}

#page_top{
    width: 50px;
    height: 50px;
    position: fixed;
    right: 0;
    bottom: -50px;
    background: $default;
    // opacity: 0.6;
    z-index: 50;
}
#page_top a{
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    text-decoration: none;
}
#page_top a::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    border: 0;
    border-top: solid 2px $white;
    border-right: solid 2px $white;
    transform: rotate(-45deg);
    margin: -2px 0 0 -6px;
}

@include sp {
    a[href*="tel:"] {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
    }
}
