.business {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 70px;
    }
    .sec__title__01 {
        margin: 70px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }
    .sec__title__04 {
        margin: 70px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }
    .sec__lead {
        font-size: 2.1rem;
        text-align: center;
        margin: 70px 0 0 0;
        padding: 0 20px;
        &:first-of-type {
            margin: 70px 0 0 0;
            padding: 0;
            border-top: 0;
        }
        @include sp {
            font-size: 1.4rem;
            margin: 60px 0 0 0;
            padding:30px 0 0 0;
            &:first-of-type {
                margin: 30px 0 0 0;
                padding: 0;
                border-top: 0;
            }
        }
    }
    .sec__photo {
        margin: 40px 0 0 0;;
        @include sp {
            margin: 20px 0 0 0;;
        }
    }
    .sec__text {
        margin: 30px 60px 0;
        &:first-of-type {
            text-align: center;
            font-size: 1.8rem;
            margin: 40px auto;
        }
        @include sp {
            &:first-of-type {
                font-size: 1.4rem;
            }
            margin: 20px 0 0 0;
        }
    }
    .sec__col__list-arrow {
        >ul {
            margin: 30px 0 0 16px;
            >li {
                font-size: 1.4rem;
                font-weight: bold;
                margin: 10px 0 0 0;
                &:before {
                    top: 6px;
                }
            }
        }
        @include sp {
            >ul {
                margin: 20px 0 0 0;
            }
        }
    }
    .sec__col__list-cir {
        >ul {
            margin: 30px 0 0 16px;
            >li {
                font-size: 1.8rem;
                font-weight: bold;
                margin: 10px 0 0 0;
                &:before {
                    top: 8px;
                }
            }
        }
        @include sp {
            >ul {
                margin: 20px 0 0 0;
                >li {
                    &:before {
                        top: 7px;
                    }
                }
            }
        }
    }
    .sec__card__02 {
        margin: 30px 0 0 0;
        flex-wrap: wrap;
        &__item {
            width: 48%;
        }
        @include sp {
            &__item {
                width: 100%;
                margin: 10px 0 0 0;
            }    
        }
    }
    .sec__card__02__item .sec__card__title {
        text-align: center;
        >span {
            display: block;
            font-size: 1.6rem;
            font-weight: 500;
            @include sp {
                font-size: 1.4rem;
            }
        }

    }
    .sec__card__title:before {
        left: 0;    bottom: -6px;
    }
    .sec__card__text {
        display: flex;justify-content: space-between;flex-wrap: wrap;
        .sec__card__block {
            width: 100%;
            line-height: 2.8rem;
            margin: 20px 0 0;
            letter-spacing: .1rem;
            &:first-of-type {
                margin: 10px 0 0;
            }
            @include sp {
                width: 100%;
                margin: 20px 0 0;
            }    
        }
    }
    .sec__card__02__item .sec__card__subttl:first-of-type {
        margin: 0 auto 10px;
    }
    .sec__card__02__item {
        margin: 40px 0 0;
        padding: 40px;
        @include sp {
            margin: 20px 0 0;
            padding: 20px;
        }
    }
    .sec__lead {
        &:last-of-type{
            margin: 60px 0 0 0;
            padding: 30px;
            border-top: 0;
            text-align: center;
            line-height: 4.2rem;
            border: 4px solid $red;    
            @include sp {
                margin: 30px 0 0 0;
                padding: 20px;
                border-top: 0;
                text-align: center;
                line-height: 2.8rem;
                border: 4px solid #e30b17;
            }
        }
    }
    &__introduction {
        width: 100%;
        line-height: 2.8rem;
        margin: 30px auto;
        letter-spacing: .0rem;
        text-align: center;
        background: #f2f2f2;
        padding: 40px 30px;
        @include sp {
            padding: 20px 20px 30px;
        }
        &__title {
            font-size: 2.4rem;
            font-weight: bold;
            @include sp {
                font-size: 1.6rem;
            }
        }
        &__text {
            text-align: left;
            margin: 20px 0 0;
        }
    }
    .sec__column__list>p:before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 8px;
        bottom: 11px;
        left: 0;
        background: #e30b17;
    }
    .sec__column__list>p:after {
        content: '';
        display: block;
        position: absolute;
        bottom:9px;
        left: -2px;
        width: 9px;
        height: 5px;
        border-left: 1px #e30b17 solid;
        border-bottom: 1px #e30b17 solid;
    }
    .sec__column__list>p {
        display: flex;
        position: relative;
        padding: 10px 0 0 20px;
        cursor: pointer;
    }
}