.corporate_philosophy {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .philosophy__bg {
        position: relative;
        background: url(../images/company/pic_img_02.png) center center /cover no-repeat #dedede;
        &:before {
            content: "";
            position: absolute;
            width: 100%;height: 100%;
            background: rgba(255,255,255,.8);
        }
        @include sp {
            background: url(../images/company/pic_img_02_sp.png) center bottom /100% no-repeat #dedede;
            padding: 0 0 100px;
        }
    }
    .sec {
        &__lead {
            margin: 70px 0 0 0;
            @include sp {
                margin: 40px 0 0 0;
            }
        }
        
        &__head__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 70px 0 0 0;
            @include sp {
                display: block;
                margin: 30px 0 0 0;
            }
        }

        &__local__01 {
            @include sp {
                margin: 30px 0 0 0;
            }
            .sec__local__nav {
                display: flex;
                justify-content: center;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &__col__list-arrow {
            >ul {
                margin: 80px 0 0 16px;
                >li {
                    font-size: 2.0rem;
                    font-weight: bold;
                    margin: 20px 0 0 0;
                    &:before {
                        top: 10px;
                    }
                }
            }
            @include sp {
                >ul {
                    margin: 30px 0 0 0;
                    >li {
                        font-size: 1.4rem;
                        font-weight: 700;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }
        &__col__list-cir {
            >ul {
                margin: 80px 0 0;
                padding: 0 40px 100px 40px;
                >li {
                    font-size: 2.0rem;
                    font-weight: bold;
                    margin: 0;
                    padding: 100px 0 0;
                    &:before {
                        content: none;
                        // top: 8px;
                    }
                }
            }
            @include sp {
                >ul {
                    margin: 30px 0 0 0;
                    padding: 0 20px 30px 20px;
                    >li {
                        font-size: 1.4rem;
                        font-weight: 700;
                        margin: 0;
                        padding: 30px 0 0;
                        &:before {
                            top: 5px;
                        }
                    }
                }
            }
        }
        &__title__04 {
            margin-top: 100px;
            @include sp {
                margin-top: 30px;
            }
        }

        &__title__astr {
            position: relative;
            top: -5px;
            left: 2px;
            font-size: 1.6rem;
            @include sp {
                font-size: 1rem;
            }
        }

        &__philosophy {
            margin: 40px 0 0 0;
            padding: 50px 30px;
            border: 1px solid $light_gray;
            @include sp {
                margin: 20px 0 0 0;
                padding: 25px 15px;
            }
        }

        &__philosophy__list {
            display: flex;
            @include sp {
                display: block;
            }
            &:not(:first-child) {
                margin-top: 27px;
                @include sp {
                    margin-top: 20px;
                }
            }
            > dt {
                font-size: 2rem;
                font-weight: bold;
                @include sp {
                    font-size: 1.6rem;
                }
            }

            > dd {
                padding-left: 30px;
                font-size: 1.6rem;
                @include sp {
                    margin-top: 10px;
                    padding-left: 0;
                    font-size: 1.4rem;
                }
            }
        }

        &__text {
            margin-top: 30px;
            @include sp {
                margin-top: 30px;
            }
        }

        &__local__02 {
            margin-top: 70px;
            @include sp {
                margin-top: 40px;
            }
            .sec__local__nav {
                display: flex;
                justify-content: flex-end;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
}