.corporate_data {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .sec {
        &__head__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 70px 0 0 0;
            @include sp {
                display: block;
                margin: 30px 0 0 0;
            }
        }

        &__local__01 {
            @include sp {
                margin: 30px 0 0 0;
            }
            .sec__local__nav {
                display: flex;
                justify-content: center;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &__row {
            margin-top: 70px;
            @include sp {
                margin-top: 40px;
            }
        }

        &__row＿table {
            width: 100%;
            th {
                padding: 30px;
                background-color: #f7f7f7;
                font-size: 1.6rem;
                text-align: left;
                @include sp {
                    padding: 15px;
                    font-size: 1.4rem;
                }
            }

            td {
                padding: 30px;
                background-color: $white;
                font-size: 1.6rem;
                @include sp {
                    padding: 15px 0 15px 15px;
                    font-size: 1.4rem;
                }
                >a {
                    color: #023796;
                    text-decoration: underline;
                    &:hover {
                        color: $red;
                        text-decoration: none;
                    }
                }
            }
        }

        &__row__data {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include sp {
                display: block;
            }
        }

        &__column__data {
            width: 48%;
            padding: 0 20px 0 0;
            @include sp {
                width: inherit;
                padding: 0;
            }
            >ul {
                >li {
                    padding: 2px 0;
                    >span {
                        border-bottom: 1px solid #333;
                        display: inline-flex;
                        margin: 0 0 4px 0;
                        padding: 0 0 4px 0;
                        @include sp {
                            padding: 0;
                        }
                    }
                    .low{
                        display: inline;
                        border-bottom: 0;
                        padding: 0 0 0 20px;
                    }
                }
            }
        }

        &__column__list {
            width: 100%;
            @include sp {
                width: inherit;
            }
            >p {
                position: relative;
                padding: 0 0 0 20px;
                cursor: pointer;
                @include sp {
                    display: block;
                    &:before {
                        top:5px;bottom:0px;left:0px;                        
                    }
                    &:after {
                        top:10px;bottom:0;left:-2px;                        
                    }
                }
                &:before {
                    content: '';
                    display:block;
                    position:absolute;
                    width:10px;height:8px;
                    bottom:9px;left:0px;
                    background:$red;
                }
                &:after {
                    content: '';
                    display:block;
                    position:absolute;
                    bottom:7px;left:-2px;
                    width:9px;height:5px;
                    border-left:1px $red solid;
                    border-bottom:1px $red solid;
                }
            }
        }

        &__local__02 {
            margin-top: 70px;
            @include sp {
                margin-top: 40px;
            }
            .sec__local__nav {
                display: flex;
                justify-content: flex-end;
                @include sp {
                    flex-flow: wrap;
                }
                li {
                    margin-left: 30px;
                    &.is-active {
                        a {
                          color: $red;
                        }
                    }
                    @include sp {
                        width: 50%;
                        margin-left: 0;
                        text-align: center;
                        &:nth-of-type(n+3) {
                            margin-top: 20px;
                        }
                    }
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $dark_gray;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
}