.btn {
    &__01 {
        width: 250px;
        border: 1px solid $red;
        margin: 0 auto;
        >a {
            display: block;
            color: $red;
            font-size: 2.0rem;
            font-weight: bold;
            text-align: center;
            padding: 20px;
            &:hover {
                color: $white;
                background: $red;
            }
        }
        @include sp {
            width: 80%;
            >a {
                font-size: 1.8rem;
                font-weight: bold;
                text-align: center;
                padding: 20px;
                &:hover {
                    color: $white;
                    background: $red;
                }
            }
        }
    }
}
.non-active {
    border: 1px solid $dark_gray !important;
    >a,>input {
        color: $dark_gray !important;
        background: $gray !important;
        pointer-events: none !important;    
    }
}