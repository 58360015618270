.contact {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .sec__title__01 {
        margin: 70px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }
    .sec__title__04 {
        margin: 70px 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }
    .sec__text {
        margin: 30px 0 0 0;
        @include sp {
            margin: 20px 0 0 0;
        }
    }
    .btn__01{
        width: 300px;
        margin: 30px auto 0;
        @include sp {
            margin: 30px auto 0;
        }
    }
    .sec__col__list-arrow {
        padding: 0 0 0 24px;
        @include sp {
            padding: 0 0 0 0;
        }
        >ul {
            >li {
                margin: 50px 0 0 0;
                >span {
                    font-weight: bold;
                }                    
                @include sp {
                    margin: 20px 0 0 0;
                }
            }
        }
    }
    &__title {
        font-weight: bold;
        margin: 30px 0 0 0;
        @include sp {
            margin: 20px 0 0 0;
        }
    }
    &__table__text {
        width: 90%;
        margin: 30px auto 0;
        @include sp {
            width: 100%;
            margin: 20px auto 0;
        }
    }
    &__table__title {
        width: 90%;
        font-weight: bold;
        margin: 30px auto 0;
        @include sp {
            margin: 20px 0 0 0;
        }
    }
    &__table {
        display: flex;flex-wrap: wrap;justify-content: center;align-items: stretch;
        width: 90%;
        // max-width: 800px;
        margin: 30px auto 0;
        >dt {
            display: flex;justify-content: center;align-items: center;
            width: 20%;
            text-align: right;
            background: $light_gray;
            padding: 20px;
            border-bottom: 2px solid $white;
        }
        >dd {
            width: 80%;
            padding: 20px;
            border-bottom: 2px solid $white;
        }
        @include sp {
            margin: 20px 0 0;
            >dt {
                width: 20%;
                padding: 10px;
            }
            >dd {
                width: 80%;
                padding: 10px;
            }
        }
    }
    &__link {
        display: block;
        max-width: 400px;
        margin: 30px 0 0 ;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            opacity: .8;
        }
        @include sp {
            width: 100%;
        }
    }
    &__notice {
        width: 70%;
        margin: 30px auto 80px;
        padding: 0 0 0 20px;
        >li {
            position: relative;
            margin: 10px 0 0 ;
            >a {
                color: #023796;
                text-decoration: underline;
                transition: all .3s ease;
                &:hover {
                    color: #e30b17;
                    text-decoration: none;
                }
            }
            &:before {
                content: "※";
                position: absolute;
                left:-20px;
            }    
        }
        @include sp {
            width: 100%;
            margin: 30px auto 40px;
        }
    }
}

.contact__form {
    padding: 0 0 150px;
    .sec__text {
        width: 80%;
        font-weight: 500;
        margin: 40px auto 0;
        &:first-child {
            margin: 70px auto 0;
        }
        @include sp {
            width: 100%;
            margin: 20px auto 0;
            &:first-child {
                margin: 30px auto 0;
            }    
        }
    }
    .contact__table {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        width: 80%;
        margin: 30px auto 0;
        @include sp {
            width: 100%;
        }
        >dt {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 35%;
            text-align: right;
            background: #f7f7f7;
            padding: 10px 20px;
            border-bottom: 10px solid #fff;
            font-weight: 700;
            >span {
                &:first-of-type {

                }
                &:nth-of-type(2) {
                    color: $white;
                    font-size: 1.2rem;
                    background: $red;
                    padding: 4px 14px;    
                }               
            }
            @include sp {
                width: 100%;
            }
        }
        >dd {
            width: 65%;
            padding: 10px 20px;
            border-bottom: 10px solid #fff;
            @include sp {
                width: 100%;
                padding: 0 0 20px;
            }
            >p {
                font-size: 1.4rem;
                color: $red;
                margin: 0 0 10px;
            }
        }
        input,textarea {
            border: 1px solid $gray;
            width: 100%;
            border-radius: 4px;
            padding: 10px;
            &:focus {
                border: 1px solid $dark_gray;
            }
            // &:hover {
            //     box-shadow: 0 0 4px rgb(204, 204, 204);
            // }
        }
    }
    .contact__notice {
        width: 80%;
        margin: 30px auto;
        padding: 20px 30px 30px 60px;
        border: 1px solid $gray;
        >li {
            &:before {
                content: "・";
                position: absolute;
                left: -20px;
            }    
        }
        @include sp {
            width: 100%;
            padding: 20px 20px 30px 40px;
        }
    }
    .checkbox__agree {
        text-align: center;
    }
    .btn__01 {
        display: block;
        margin: 50px auto 0;
    }
}

.radio__block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
}
.radio {
    width: 50%;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        max-width: 200px;
        + .radio-label {
            &:before {
                content: '';
                background: $light_gray;
                border-radius: 100%;
                border: 1px solid darken($light_gray, 25%);
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top:  0;
                margin-right: 1em; 
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            + .radio-label {
            &:before {
                background-color: $red;
                box-shadow: inset 0 0 0 4px $light_gray;
            }
            }
        }
        &:focus {
            + .radio-label {
            &:before {
                outline: none;
                border-color: $red;
            }
            }
        }
        &:disabled {
            + .radio-label {
            &:before {
                box-shadow: inset 0 0 0 4px $light_gray;
                border-color: darken($light_gray, 25%);
                background: darken($light_gray, 25%);
            }
            }
        }
        + .radio-label {
            &:empty {
            &:before {
                margin-right: 0;
            }
            }
        }
    }
}
.select {
    position: relative;
    .tri{
        position: absolute;
        right:10px;top:50%;
        border-right: 8px solid transparent;
        border-bottom: 10px solid $dark_gray;
        border-left: 8px solid transparent; 
        margin: -5px 0 0;
        z-index: 50;
        transition: all .3s ease;
        transform: rotate(180deg);
        &__rotate {
            transform: rotate(0deg) !important;
        }
    }
    span.msg {
        color: #555;
        padding: 5px 0 10px;
        display: inherit
    }
    .container {
        width: 100%;
    }
    
    /*Styling Selectbox*/
    .dropdown {
        width: 100%;
        display: inline-block;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid $gray;
        transition: all .3s ease;
        position: relative;
        height: 100%;
        text-align: left
    }
    .dropdown .select {
        cursor: pointer;
        display: block;
        padding: 10px
    }
    .dropdown .select > i {
        font-size: 13px;
        color: #888;
        cursor: pointer;
        transition: all .3s ease-in-out;
        float: right;
        line-height: 20px
    }
    .dropdown:hover {
        box-shadow: 0 0 4px rgb(204, 204, 204)
    }
    .dropdown:active {
        background-color: #f8f8f8
    }
    .dropdown.active:hover {
        // box-shadow: 0 0 4px rgb(204, 204, 204);
        border-radius: 2px 2px 0 0;
        background-color: #f8f8f8
    }
    .dropdown.active {
        background-color: #f8f8f8
    }
    .dropdown.active .select > i {
        transform: rotate(-90deg)
    }
    .dropdown .dropdown-menu {
        position: absolute;
        background-color: #fff;
        width: calc(100% + 2px);
        left: -1px;top: 0;
        border: 1px solid $dark_gray;
        border-radius: 4px;
        overflow: hidden;
        display: none;
        overflow-y: auto;
        z-index: 40;
        box-shadow: 0 0 4px rgb(204, 204, 204);
    }
    .dropdown .dropdown-menu li {
        padding: 10px 10px 10px 40px;
        transition: all .2s ease-in-out;
        cursor: pointer
    } 
    .dropdown .dropdown-menu {
        padding: 0;
        list-style: none
    }
    .dropdown .dropdown-menu li:hover {
        color: $red;
        // background-color: #f2f2f2
    }
    .dropdown .dropdown-menu li:active {
        background-color: #e2e2e2
    }
    .checkmark {
        position:relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 18px;
            left: 18px;
            width: 12px;
            height: 7px;
            border-left: 2px solid $black;
            border-bottom: 2px solid $black;
            transform: rotate(-45deg);
    
        }
    }
}
.cbx {
    margin: auto;
    user-select: none;
    cursor: pointer;
    span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0,0,0);
        padding: 0 0 0 20px;
        &:first-child {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            transform: scale(1);
            vertical-align: middle;
            border: 1px solid #9098A9;
            transition: all .2s ease;
            padding: 0;
            svg {
                position: absolute;
                top: 3px;
                left: 1px;
                fill: none;
                stroke: #FFFFFF;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 16px;
                stroke-dashoffset: 16px;
                transition: all .3s ease;
                transition-delay: .1s;
                transform: translate3d(0,0,0);
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: $red;
                    display: block;
                    transform: scale(0);
                    opacity: 1;
                    border-radius: 50%;
                }
            }
            &:last-child{
                padding-left: 8px;
            }
        }
    }
    &:hover span:first-child {
        border-color: $red;  
    }
}
.inp-cbx:checked + .cbx {
    span{
        &:first-child{
            background: $red;
            border-color: $red;
            animation: wave .4s ease;    
        }
        svg{
            stroke-dashoffset: 0;

        }
        &:before{
            transform: scale(3.5);
            opacity: 0;
            transition: all .6s ease;
        }
    }    
}

@keyframes wave {
    50%{
        transform: scale(.9);
    }
}

.select__adjust {
    display: none;
}

.btn__01 {
    >input {
        width: 100%;
        display: block;
        color: #e30b17;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        padding: 20px;
        transition: all .3s ease;
    }
}

.adjust__contact {
    .confirm__backlink {
        width: 50%;
        text-align: center;
        background: #e30b17;
        margin: 0 auto;
        >a {
            color: #fff;
            padding: 20px;
            display: block;
        }
        >a>span:before　{
            content: none;
            display: block;
            color: #fff;
            padding: 20px;
            cursor: pointer;
        }
        >a>span:after　{
            content: none;
        }
    }
}
