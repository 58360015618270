.product {
    padding: 0 0 150px;
    @include sp {
        padding: 0 0 100px;
    }
    .sec__title__01 {
        margin: 70px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }
    .sec__lead {
        font-size: 2.4rem;
        text-align: center;
        margin: 70px 0 0 0;
        padding: 0 20px;
        @include sp {
            font-size: 1.4rem;
            margin: 30px 0 0 0;
            padding: 30px 0 0 0;
        }
    }
    .sec__text01 {
        margin: 70px 0;
        @include sp {
            margin: 30px 0;
            padding: 0;
            border-top: 0;
        }
    }
    .sec__text02 {
        margin: 40px 0 0 0;
        >a {
            color: #023796;
            text-decoration: underline;
            &:hover {
                color: $red;
                text-decoration: none;
            }
        }
        @include sp {
            margin: 30px 0 0 0;
        }
    }
    .sec__col__text {
        margin: 30px 0 0 0;
        @include sp {
            margin: 30px 0 0 0;
        }
    }

    @include sp {
        .sec__odd .sec__col__block:nth-child(2) ,
        .sec__even .sec__col__block:nth-child(2) {
            padding: 10px 0 40px 0;
        }
    }
    .sec__col {
        flex-direction: row;
        .sec__col__block:nth-child(2) {
            padding: 40px 0 40px 40px;
            @include sp {
                padding: 20px 0 40px;
            }
        }
    }
    .sec__odd {
        flex-direction: row-reverse;
        .sec__col__block:nth-child(2) {
            padding: 40px 40px 40px 0px;
            @include sp {
                padding: 20px 0 40px;
            }
        }
    }
    .sec__col__block {
        &:first-of-type {
            width:40%
        }
        width:60%;
        padding: 30px 0;
        @include sp {
            width:100%;    
            padding: 0;
            &:first-of-type {
            width:100%
            }
        }
    }
    .sec {
        &__card {
            &__01 {
                display: flex;flex-wrap: wrap;justify-content: center;align-items: stretch;
                margin-top: 40px;
                @include sp {
                    margin: 0;
                }
                // &:after{
                //     content:"";
                //     display:block;
                //     width: calc(96% /3);
                //     height:0;
                //   }
                &__item{
                    width: calc(94% /3);
                    background: $white;
                    border: 1px solid $gray;
                    margin: 0 1%;
                    padding: 0 0 27px 0;
                    @include sp {
                        width: calc(100% /1);
                        margin-top: 30px;
                        padding: 0 0 25px 0;
                    }
                    &:nth-child(n+4) {
                        margin-top: 40px;
                        @include sp {
                            margin-top: 30px;
                        }
                    }
                    .sec__card__title {
                        position: relative;
                        margin-top: 30px;
                        padding: 0 30px 0;
                        font-size: 1.8rem;
                        font-weight:bold;
                        text-align: left;
                        @include sp {
                            margin-top: 12px;
                            padding: 0 12px 0;
                            font-size: 1.6rem;
                        }
                    }
                    .sec__card__text {
                        margin: 10px 0 0 0;
                        padding: 0 30px 0;
                        @include sp {
                            margin-top: 12px;
                            padding: 0 12px 0;
                        }
                    }
                }
            }
        }
    }
    .sec__photo {
        width: 80%;
        display: block;
        margin: 20px auto 0;
        @include sp {
            margin: 30px auto 0;
        }
    }
    &__disclaimer {
        margin: 40px 0 0 0;
        @include sp {
            margin: 20px 0 0 0;
        }
        >p {
            font-size: 1.6rem;
            font-weight: bold;
        }
        >ul {
            padding: 20px 0 0 30px;
            font-size: 1.4rem;
            @include sp {
                padding: 10px 0 0 20px;
            }
            >li {
                position: relative;
                padding: 10px 0;
                &:before {
                    content: "※";
                    display: inline-block;
                    position: absolute;
                    top: 11px;
                    left: -19px;
                    @include sp {
                        top: 10px;
                    }
                }
                >p {
                    display: block;
                    padding: 10px 0 10px 12px;
                    >a {
                        position: relative;
                        color: #023796;
                        text-decoration: underline;
                        transition: all .3s ease;
                        &:before {
                            content: "";
                            position: absolute;
                            top: 50%;left: -14px;
                            width: 6px;height: 6px;
                            border: 0;
                            border-top: solid 1px #333;
                            border-right: solid 1px #333;
                            transform: rotate(45deg);
                            margin: -4px 0 0 0;
                            transition: all .3s ease;
                        }    
                        &:hover {
                            color: #e30b17;
                            text-decoration: none;
                            &:before {
                                border-top: solid 1px #e30b17;
                                border-right: solid 1px #e30b17;
                            }
                        }
                    }
                }
            }
        }
    }
    &__list {
        display: flex;justify-content: space-between;align-items: flex-start;flex-wrap:wrap;
        &__item {
            width: calc(96% / 2);
            margin: 30px 0 0;
            &:first-of-type {
                width: 100%;
                >ul {
                    display: flex;
                    flex-wrap: wrap;
                    >li {
                        width: 50%;
                        &:first-of-type {
                            width: 100%;
                        }
                        @include sp {
                            width: 100%;
                        }
                    }
                }
            }
            >ul {
                >li {
                    font-size: 1.6rem;
                    color: $default;
                    padding: 4px 0 4px 16px;
                    &:first-of-type{
                        position: relative;
                        font-size: 1.6rem;
                        font-weight: bold;
                        margin: 0 0 20px 0;
                        padding: 4px 0;
                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;height: 1px;
                            bottom: -4px;left: 0;
                            background: $gray;
                        }
                    }
                }
            }
            @include sp {
                width:100%;
                >ul {
                    >li {
                        font-size: 1.4rem;
                        &:first-of-type {
                            margin: 0 0 20px ;
                        }
                    }    
                }
            }
        }
    }
    &__introduction {
        width: 80%;
        line-height: 2.8rem;
        margin: 30px auto;
        letter-spacing: .1rem;
        text-align: center;
        background: #f2f2f2;
        padding: 20px 30px 30px;
        @include sp {
            width: 100%;
        }
        &__title {
            position: relative;
            display: table;
            position: relative;
            font-weight: 700;
            text-align: center;
            margin: 20px auto 0;
            padding: 0 20px;
            &:before {
                content: "";
                position: absolute;
                top: 48%;
                left: 0;
                width: 10px;
                height: 1px;
                background: #333;
            }
            &:after {
                content: "";
                position: absolute;
                top: 48%;
                right: 0;
                width: 10px;
                height: 1px;
                background: #333;
            }
        }
        &__text {
            @include sp {
                text-align: left;
            }
        }
    }
}